import React, { useEffect } from 'react';
import { navigate } from '@reach/router';

import './App.scss';
import PreviewAdludio from './pages/PreviewAdludio';

import decodePayload from '@adludio/react-sso/dist/decodePayload';
import { AuthContext } from '@adludio/react-sso';

const App = () => {

  const payload = decodePayload();
  const { auth: { authStatus } } = React.useContext(AuthContext);

  useEffect(() => {
    if (!payload) return;
    if (authStatus !== 'success') return;

    navigate(payload.url).catch(console.log);
  }, [authStatus, payload]);


  return (

    <PreviewAdludio />

  );
};

export default App;
