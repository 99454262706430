import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@adludio/components';

import getEnv, { Env } from '@adludio/react-sso/dist/getEnv';
import { AuthProvider } from '@adludio/react-sso';

/*
 * Figuring out the correct path to our other services
 * by looking at current node env, basically:
 *
 * If env is:
 * production (live)     => sso.adludio.com
 * development (local)   => dev.sso.adludio.com
 * testing (alpha)       => testing.sso.adludio.com
 * staging (beta)        => staging.sso.adludio.com
 *
 */

const env = getEnv(process.env.REACT_APP_BUILD_ENV);
const envPrefix = (env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.');

export interface PreviewConfig {
  env: Env,
  ssoBEURL: string,
  ssoFEURL: string,
  portalURL: string
}

export const initialConfig: PreviewConfig = {
  env,
  ssoBEURL: `//${envPrefix}api.sso.adludio.com`,
  ssoFEURL: `//${envPrefix}sso.adludio.com`,
  portalURL: `//${envPrefix}portal.adludio.com`
};

ReactDOM.render(
  <ThemeProvider>
    <AuthProvider ssoBEURL={initialConfig.ssoBEURL}>
      <App />
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById('root')
);
